import React from "react"
import { PortableText } from '@portabletext/react'
import { Columns, Column } from "../CompoundComponents/Columns"
import { Section, SectionTitle } from "../CompoundComponents/Section"

const AboutCompany = ({data}) => {
  return (
    <Section medium>
      <Columns vcentered>
        <Column is11 offset1>
          <p className="is-size-5-desktop portable-text">
            {
              data.edges.map((item, i) => (
                  <div key={i}>
                    <PortableText
                        value={item.node.block}
                    />
                  </div>
              ))
            }
          </p>
        </Column>
      </Columns>
    </Section>
  )
}

export default AboutCompany
