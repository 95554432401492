import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Columns, Column } from "../CompoundComponents/Columns"
import { Section, SectionTitle } from "../CompoundComponents/Section"

const AboutSection = ({ data, intl }) => {
    console.log(data);
  return (
    <Section medium white>
        <div id="management">
            <Columns vcentered tcentered>
                <Column is12>
                    <div data-sal="slide-up" data-sal-delay="100" data-sal-easing="ease">
                        <SectionTitle bottom>Руководство</SectionTitle>
                    </div>
                </Column>
                {
                    data.edges.map((item, i) => (
                        <Column is4>
                            <GatsbyImage
                                image={getImage(item.node.image.asset.gatsbyImageData)}
                            />
                            <p className="title is-6">{item.node.name}</p>
                            <p className="subtitle is-6">{item.node.position}</p>
                        </Column>
                    ))
                }
            </Columns>
        </div>
    </Section>
  )
}

export default AboutSection
