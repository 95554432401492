import React from "react"
import Sticky from "react-sticky-el"
import { graphql } from "gatsby"
import SEO from "../components/SEO/index"
import AboutHero from "../components/Sections/AboutHero"
import AboutSection from "../components/Sections/AboutSection"
import AboutCompany from "../components/Sections/AboutCompany"
import StyledBackgroundAbout from "../components/BackgroundAbout"
import NavBar from "../components/navbar"

const AboutPage = ({ data }) => {
  return (
    <>
      <SEO title="UZMBC" description="Multi Business Company - О компании" />
      <Sticky className="sticky-selector" topOffset={150}>
        <div>
          <NavBar />
        </div>
      </Sticky>
      <StyledBackgroundAbout>
        <AboutHero />
      </StyledBackgroundAbout>

      <AboutCompany data={data.sanityAbout} />
      <AboutSection data={data.management} />
    </>
  )
}

export const AboutPageQuery = graphql`
  query {
    about: allAboutYaml {
      edges {
        node {
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 150)
            }
          }
          name
          position
        }
      }
    }
    management: allSanityManagement {
        edges {
          node {
            name
            position
            image {
              asset {
                gatsbyImageData(width: 150)
              }
            }
          }
        }
      }
    sanityAbout: allSanityAbout {
        edges {
          node {
            block: _rawBlock(resolveReferences: {maxDepth: 5})
          }
        }
      }
  }
`

export default AboutPage
